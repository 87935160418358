import React from "react";
import { Link } from "gatsby";

import "../scss/style.scss";

const ListLink = props => (
  <li style={{ display: `inline-block`, marginRight: `20px` }}>
    <Link to={props.to}>{props.children}</Link>
  </li>
);

export default ({ children }) => (
  <div style={{ margin: `0` }}>
    <div id="header">
      <div className="container">
        <div className="inner">
          <div className="logo five columns">
            <Link to="/">
              <h1>MUDr. Rastislav Šušol</h1>
            </Link>
            <div id="mission">Urologická ambulance</div>
          </div>

          <div className="seven columns">
            <div id="main-menu" className="clearfix">
              <div className="inner">
                <ul id="nav">
                  <ListLink to="/">Úvod</ListLink>
                  <ListLink to="/profil/">Profil</ListLink>
                  <ListLink to="/sluzby/">Služby</ListLink>
                  <ListLink to="/ordinace/">Ordinace</ListLink>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="main">
      <div class="container">{children}</div>
    </div>

    <div id="footer">
      <div className="container">
        <div className="seven columns">
          <div className="inner">
            <div id="footer-menu">
              <ul id="footer-nav" className="menu inline clearfix">
                <ListLink to="/">Úvod</ListLink>
                <ListLink to="/profil/">Profil</ListLink>
                <ListLink to="/sluzby/">Služby</ListLink>
                <ListLink to="/ordinace/">Ordinace</ListLink>
              </ul>
            </div>
          </div>
        </div>
        <div className="five columns">
          <div className="inner">
            <div className="u-pull-right">&copy; 2018</div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
