import React from "react";
import Layout from "../components/layout";
import Helmet from "react-helmet";

export default () => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Profil | MUDr. Rastislav Šušol</title>
    </Helmet>

    <div id="page" class="sixteen columns">
      <div class="inner">
        <div id="page-header">
          <h1 id="page-title" className="title">
            Profil
          </h1>
        </div>

        <div id="content" className="content clearfix">
          <p>
            V roce 1995 jsem ukončil studium na Lékařské fakultě Univerzity
            Karlovy v Hradci Králové, ihned jsem nastoupil na urologickém
            oddělení Nemocnice ve Svitavách, kde jsem postupně získal erudici v
            otevřené a endoskopické urologické operativě. Nyní pracuji 1 den v
            týdnu ve Svitavské nemocnici a.s., kde operuji &nbsp;svoje pacienty.
            4 dny v týdnu pracuji ve svých privátních ordinacích.&nbsp;
          </p>
          <div>
            <ul>
              <li>
                1995 - ukončení studia na Lékařské fakultě Univerzity Karlovy v
                Hradci Králové
              </li>
              <li>
                1995 -&nbsp;nástup na urologické oddělení Nemocnice ve Svitavách
              </li>
              <li>1998 -&nbsp;atestace z urologie</li>
              <li>
                2001 - licence a odborná způsobilost k samostatné činnosti v
                oboru urologie od České lekařské komory
              </li>
              <li>2001 -&nbsp;privátní urologická ambulance</li>
              <li>Četné&nbsp;kurzy, stáže, školení</li>
            </ul>
          </div>
          <p>
            V roce 2001 udělena licence a odborná způsobilost k samostatnému
            výkonu v oboru urologie.
          </p>
          <p>
            V roce 2001 zkrácený úvazek na urol. oddělení a otevřena urol.
            ambulance v Letovicích, postupně rozšíření na urol. ambulantní
            činnost i v Blansku.
          </p>
          <p>
            Mým cílem je poskytnout pacientovi co možná nejkomplexnejší službu -
            od první návštěvy přes vyšetření, léčbu ( medikamentóizní, operační
            ) až po pooperační sledování.
          </p>

          <h2>Vybavení</h2>
          <p>Mám moderně vybavenou ordinaci</p>
          <p>
            Jednodušší operace provádím na urologickém oddělení Svitavské
            nemocnice a.s., náročnější operace u onkologicky nemocných se
            provádí na spolupracujících pracovištích v Brně.
          </p>
          <ul>
            <li>ultrazvuk medison R 7 včetně abdominální a rektální sondy</li>
            <li>flexibilní cystoskop Olympus CYF 5</li>
            <li>uroflowmetr flowstar</li>
            <li>biopsy gun</li>
            <li>Biocon 2000W</li>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
);
